const showMoreFlat = document.querySelector('.js-flat-show-more');

window.addEventListener('load', function () {
	if (!showMoreFlat) {
		return;
	}
	const list = document.querySelectorAll('.tlw__flat .content ul li');

	if (list.length < 19) {
		showMoreFlat.style.display = 'none';
	}
	showMoreFlat.addEventListener('click', function (e) {
		e.preventDefault();
		const text = this.querySelector('span');
		const element = this.parentNode.closest('.content');

		element.classList.toggle('active');
		if (element.classList.contains('active')) {
			text.textContent = 'Ver menos';
		} else {
			text.textContent = 'Ver todas';
		}
	});
});
